body {
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background: #cccccc;
  color: #444444;
}

header {
  color: #ffffff;
  background: #000000;
  padding: .5rem;
  margin-bottom: 2rem;
}

h1 {
  margin: 0;
}

header p {
  margin: 0;
}

p {
  margin: 1rem 0;
}
.dialogue {
  border: solid 2px #000000;
  color: #000000;
  background: #ffffff;
  padding: 1rem;
  border-radius: 1rem;
  margin: 1rem;
}

.thinking {
  font-style: italic;
  color: #444444;
}

.wordline {
  font-size: 3rem;
}

.wordline .word {
  font-weight: bold;
}

.buttons {
  display: flex;
  justify-content: center;
}

.buttons button {
  display: block;
  font-size: 1.5rem;
  padding: 1rem;
  margin: .25rem;
  color: #ffffff;
}

.buttons .yes {
  background: #008000;
}

.buttons .no {
  background: #800000;
}

.number-note {
  font-size: .8rem;
}

.answer {
  font-size: 3rem;
}

.possibles {
  margin: 3rem 1rem 1rem;
}

.possible-articles {
  font-style: italic;
}

.possibles ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.possibles li {
  margin: .25rem;
  border: solid 1px #000000;
  color: #000000;
  background: #ffffff;
  padding: .1rem .25rem;
  border-radius: .25rem;

}

.possibles ul li:nth-child(2n) {
  border: solid 1px #ffffff;
  color: #ffffff;
  background: #000000;
}

.bottom-buttons {
  margin: 3rem 1rem 0;
}

.info {
  font-size: .8rem;
  margin: 1rem;
}

a {
  color: #000000;
}